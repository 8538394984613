import api from './request';

// 查询列表
export const getVGroupList = (data: { name: string; page: Basic.Page }) =>
  api.post<VirtualGroup.ListData>('/auth-admin/group/query', data);

// 新建/更新虚拟组数据
export const createOrUpdateVGroup = (data: VirtualGroup.GroupItem) =>
  api.post('/auth-admin/group/createOrUpdate', data);

// 删除虚拟组
export const deleteVGroups = (id: number) => api.post('/auth-admin/group/delete', { id });

// 获取虚拟组下的绑定角色
export const getVGroupsRoles = (data: { groupId: number; myAuthProjectIds?: number[] }) =>
  api.post<{ roleList: Role.RoleItem[] }>('/auth-admin/group/getGroupsRoles', data);

// 获取成员列表
export const getVirtualGroupUser = (groupId: number) =>
  api.post<{ users: User.UserItem[] }>('auth-admin/group/queryUser', { groupId });

// 绑定用户
export const bindUser = (data: { groupId: number; uids: number[] }) =>
  api.post('/auth-admin/group/bindUser', data);

// 解绑用户
export const unbindUser = (data: { groupId: number; uids: number[] }) =>
  api.post('/auth-admin/group/unbindUser', data);
