import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "e-drawer__content" }
const _hoisted_2 = { class: "e-drawer__scroll" }
const _hoisted_3 = {
  key: 0,
  class: "e-drawer-default__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_e_button = _resolveComponent("e-button")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, _mergeProps({
    "custom-class": "e-drawer",
    title: _ctx.title
  }, _ctx.$attrs, { onClose: _ctx.close }), _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ]),
    _: 2
  }, [
    (_ctx.showFooter)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            (_ctx.confirmText || _ctx.cancelText)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_ctx.confirmText)
                    ? (_openBlock(), _createBlock(_component_e_button, {
                        key: 0,
                        type: "main",
                        onClick: _ctx.confirm
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.cancelText)
                    ? (_openBlock(), _createBlock(_component_e_button, {
                        key: 1,
                        type: "secondary",
                        onClick: _ctx.cancel
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "footer")
          ]),
          key: "0"
        }
      : undefined
  ]), 1040, ["title", "onClose"]))
}