// 虚拟组状态
export const virtualGroupTextMap = new Map([
  [0, '停用'],
  [1, '正常']
]);

export const virtualGroupColorMap = new Map([
  [0, 'info'],
  [1, 'success']
]);
